<template lang="pug">
div
  .castListWrap(v-if="items.length > 0")
    h3 {{listName}}
    ul(v-if="showLess")
      li(v-for="credit in items.slice(0,showLessNumber)")
        router-link(:to="`/${type}/${credit[type]._id}`")
          .cast
            img.castImg.active( v-bind:src="`${credit[type].thumbnail}`" @error="imageLoadError")
            img.castImg( src="../assets/img/placeholder_thumb.jpg")
            .castInfo
              .castName(v-if="credit[type].nameCH")
                p.nameTitle {{credit[type].nameCH}}
                p.subTitle {{credit[type].nameEN}}
                //- p.subTitle {{credit.model}}
              .castName(v-else-if="credit[type].nameEN")
                p.nameTitle {{credit[type].nameEN}}
              span.role(v-if="credit.model=='cast' && credit.role && credit.roleCH") {{credit.roleCH}}
                p(style="font-size:12px;") {{credit.role}}
                p(v-if="credit.totalEpisodes" style="font-size:12px;") {{credit.totalEpisodes}} 劇集
              span.role(v-else-if="credit.model=='cast' && credit.role") {{credit.role}}
                p(v-if="credit.totalEpisodes" style="font-size:12px;") {{credit.totalEpisodes}} 劇集
              p.role(v-else-if="credit.model=='crew' && credit.job") {{credit.job}}
              p.role(v-else) --
              p.role(v-if="type !='star' && credit[type].releaseDateTW") {{credit[type].releaseDateTW | luxon("yyyy")}}
              p.role(v-else-if="type !='star' && credit[type].releaseDateUS") {{credit[type].releaseDateUS | luxon("yyyy")}}
              p.role(v-else-if="type !='star'") N/A
      .listMore(v-if="items.length > showLessNumber && showLess" @click="showLess = !showLess") 
        span.pointer 更多
      
    ul(v-else)
      li(v-for="credit in items")
        router-link(:to="`/${type}/${credit[type]._id}`")
          .cast
            img.castImg.active( v-bind:src="`${credit[type].thumbnail}`" @error="imageLoadError")
            img.castImg( src="../assets/img/placeholder_thumb.jpg")
            .castInfo
              .castName(v-if="credit[type].nameCH")
                p.nameTitle {{credit[type].nameCH}}
                p.subTitle {{credit[type].nameEN}}
                //- p.subTitle {{credit.model}}
              .castName(v-else-if="credit[type].nameEN")
                p.nameTitle {{credit[type].nameEN}}
              span.role(v-if="credit.model=='cast' && credit.role && credit.roleCH") {{credit.roleCH}}
                p(style="font-size:12px;") {{credit.role}}
                p(v-if="credit.totalEpisodes" style="font-size:12px;") {{credit.totalEpisodes}} 劇集
              span.role(v-else-if="credit.model=='cast' && credit.role") {{credit.role}}
                p(v-if="credit.totalEpisodes" style="font-size:12px;") {{credit.totalEpisodes}} 劇集
              p.role(v-else-if="credit.model=='crew' && credit.job") {{credit.job}}
              p.role(v-else) --
              p.role(v-if="type !='star' && credit[type].releaseDateTW") {{credit[type].releaseDateTW | luxon("yyyy")}}
              p.role(v-else-if="type !='star' && credit[type].releaseDateUS") {{credit[type].releaseDateUS | luxon("yyyy")}}
              p.role(v-else-if="type !='star'") N/A
      .listMore(v-if="items.length > showLessNumber && showLess == false" @click="showLess = !showLess")
        span.pointer 較少
  .castListWrap(v-else-if="items.length == 0")
    h3 {{listName}}
    p 0 位工作人員


              
</template>
<script>
export default {
  name: 'mtesList',
  props: {
    items:Array,
    listName:String,
    type:String,
    showLessNumber:Number,
  },
  components:{

  },

  data(){
    return{
      showLess:true,
    }
  },
  methods:{
    imageLoadError(event){
      event.target.classList.toggle('active')
      event.target.nextSibling.classList.toggle('active')
    }
  },
  created(){
  }
}
</script>
<style lang="scss" scoped>
.role{
  text-align:right;
  font-size:14px;
  overflow-wrap:anywhere;
  width:50%;
}
.castListWrap{
  width:100%;
}
.listMore{
  margin-top:10px;
}
.cast{
  display: flex;
  // margin:10px 0;
  padding:5px;
  align-items: center;
  box-shadow:0px 0px 0px 0px rgba(0,0,0,.1);
  // border:solid 1px black;
  transition:transform .2s, background-color .2s, box-shadow .3s;
  .castImg{
    &.active{
      display:flex;
    }
    display:none;
    width:50px;
    height:75px;
    margin-right:15px;
    background-color:lightgrey;
  }
  .castInfo{
    width:100%;
    display:flex;
    justify-content: space-between;
    align-items: center;
    .nameTitle{
      font-weight:bold;
    }
    .subTitle{
      font-size:12px;
      // flex-direction: ;
    }

  }
}
.cast:hover{
  background:rgba(0,0,0,.05);
  // box-shadow:0px 2px 2px 2px rgba(0,0,0,.1);
  // box-shadow:0 12px 19.2px -7.2px rgb(5 5 5 / 20%);
  // transform:translateY(-1px);
}
.listMore{
  display:flex;
  justify-content: flex-end;
}
.castName{
  width:50%;
}
@media screen and(max-width:768px) {
  .castName{
    width:50%;
  }
  .cast .castInfo{
    flex-wrap:wrap;
  } 
  .castListWrap{
    li{
      margin:20px 0;
    }
  }
}
@media screen and (max-width:425px){
  .castName{
    width:100%;
    margin-bottom:10px;
    
  }
  .cast .castImg{
    width:60px;
    height:90px;
  }
}
</style>
