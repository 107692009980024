
<template lang="pug">
.wrapper.i-wrap
  .section-left
    LeftBar    
  .section-mid
    .star-wrap(v-if="isLoading" style="width:100%; height:200px;display:flex; justify-content:center;align-items:center;")
      Spinner
    transition(name="slideup")
      .profile-wrap(v-if="!isLoading")
        .profile-top
          .profile-img
            img(:src="profile.photo")
          .profile-name
            h1 {{profile.displayName}}
        .profile-reviews
          .reviews-list
            h3 {{profile.displayName}} 的評論
            .review(v-for="review in reviews")
              router-link(:to="`/movie/${review.movie._id}`" style="display:flex")
                img.review-img(:src="review.movie.thumbnail")
                div
                  h3(v-if="review.movie.nameCH") {{review.movie.nameCH}} 
                  h3(v-else) {{review.movie.nameEN}}
                  img.tofu-score(v-for="n in review.score" src="@/assets/img/awakescore1.png")
  .section-right
</template>

<script>
import mtesList from '@/components/mtesList';
import VClamp from 'vue-clamp'
import Spinner from 'vue-simple-spinner'
import LeftBar from '@/components/LeftBar'
import { mapState } from "vuex";
export default {
  name: 'Profile',
  components:{
    mtesList,
    LeftBar,
    VClamp,
    Spinner
  },
  props:{
    id:String
  },
  computed:{
    ...mapState(['user']),
  },
  data(){
    return{
      isLoading:true,
      profile:null,
      articles:[],
      reviews:[]
    }
  },
  
  methods:{
    toggleClamp(){
      this.lines > 0 ? this.lines = 0 : this.lines = 3;
      this.starSummary = !this.starSummary;
    },
  },
  async created(){
    var {data} = await this.axios.get(`/api/profile/${this.id}`)
    this.profile = data.profile
    this.articles = data.articles
    this.reviews = data.reviews;

    this.isLoading = false;
    
  },
  jsonld(){
    // var x = this.isLoading;
    
    // var jsonldInfo = {
    //   "@context": "http://schema.org/",
    //   "@type": "Person",
    //   "name": '',      
    //   "description": "",
    //   "url": "",
    //   "image":"",
    // };
    // if (!x){
    //   jsonldInfo.description = this.star.description
    //   jsonldInfo.url = `https://stinkytofu.tw/star/${this.star._id}`
    //   jsonldInfo.image = `${this.star.poster}`
      
    //   if (this.star.nameCH){
    //     jsonldInfo.name = this.star.nameCH
    //   }else{
    //     jsonldInfo.name = this.star.nameEN
    //   }
    // }
    // return jsonldInfo
  },
  metaInfo() {
    var x = this.isLoading;
    var theName = '';
    if (!x){
      if (this.profile.displayName){
        theName = this.profile.displayName
      }else{
        theName = 'No Username'
      }
    }
    return {
      meta:[{description:'',}],
      title: this.isLoading ? 'Loading...': theName,
      titleTemplate: "%s - 臭豆腐 - 電影∣影集∣評分∣評論"
    }    
  },
}
</script>
<style lang="scss" scoped>
  .review{
    margin:10px 0;
  }
  .profile-top{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .profile-img{
    width:150px;
    img{
      width: 100%;
    }
  }
  .review-img{
    width:55px;
    margin-right:10px;
  }
  .tofu-score{
    width:25px;
    height:25px;
    margin-right:5px;
  }
</style>
